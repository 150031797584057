import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FirstLetterPipe } from './pipes/first-letter.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { ApiEndpointsService } from './services/api-calls-services/api-endpoints.service';
import { ApiHttpService } from './services/api-calls-services/api-http.service';
import { APICallsConstants } from '../configs/api-calls-constants.config';

@NgModule({
  declarations: [FirstLetterPipe, SafePipe],
  imports: [CommonModule],
  exports: [FirstLetterPipe, SafePipe],
  providers:[
    ApiEndpointsService,
    ApiHttpService,
    APICallsConstants  
  ]
})
export class CoreModule { }
