import { Injectable } from '@angular/core'; 
import { HttpClient } from '@angular/common/http';
import { LogPublisher, LogConsole,LogLocalStorage, LogWebApi, LogPublisherConfig } from "./log-publishers";
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';



const PUBLISHERS_FILE = "assets/log-publishers.json";

@Injectable({
    providedIn: 'root'
  })

export class LogPublishersService {

publishers: LogPublisher[] = [];

  constructor(private http: HttpClient) {

}

async loadConfiguration(): Promise<any> {
    const config = await this.http
      .get<LogPublisherConfig[]>(PUBLISHERS_FILE,{
        responseType: 'json'}).pipe(
    
                    map( response=>{           
                               
                      let configResponse: LogPublisherConfig[] = response as LogPublisherConfig[];            
                        return configResponse;
                    }),
                    catchError(this.handleErrors)
      )
      .toPromise();
    Object.assign(this, config);
    return config;
  }

private handleErrors(error: any):
Observable<any> {
    let errors: string[] = [];
    let msg: string = "";
    
    msg = "Status: " + error.status;
    msg += " - Status Text: " + error.statusText;
    // if (error.json()) {
    //crapa la partea asta
    //     msg += " - Exception Message: " + error.json().exceptionMessage;
    // }
    errors.push(msg);
    console.error('An error occurred', errors);
    console.error(error);
//     return Observable.throw(errors);
      return throwError(errors);    
}

     buildPublishers(loggersConfig:LogPublisherConfig[]) {
        //cream un array de publishers
       //fiecare publishers va fi parcurs si in felul acesta se vor stoca erorile(sau diverse informatii) in consola, localStorage si intr-o baza de date prin intermediul unui API

       let logPub:LogPublisher;
       
          for(let pub of loggersConfig.filter(p=> p.isActive)){

             switch(pub.loggerName.toLowerCase()){
                   
                //cream o instanta pentru clasa LogConsole     
                case "console":                
                    logPub = new LogConsole();
                    break;
                //cream o instanta pentru clasa LogLocalStorage
                case "localstorage":                    
                    logPub = new LogLocalStorage(pub.loggerLocation, "localstorage");
                    break;
                //cream o instanta pentru clasa LogWebApi
                case "webapi":
                    logPub = new LogWebApi(this.http, pub.loggerLocation, "webapi");
                    break;  
             }

             logPub.location = pub.loggerLocation;//setam locatia acolo unde e cazul(ori denumirea din local storage ori metoda folosita din API, pt consola este doar empty string)
             this.publishers.push(logPub); //adaugam in array-ul principal
        }

}

}
