
import { Injectable } from '@angular/core';
import { LogLocalStorage, LogPublisher, LogWebApi } from './log-publishers';
import { LogPublishersService } from './log-publishers.service';



export enum LogLevel {
  All = 0,
  Debug = 1,
  Info = 2,
  Warn = 3,
  Error = 4,
  Fatal = 5,
  Trace = 6,
  Off = 7
}
export enum LogLocationEnum{
  FRONTEND = 0,
  BACKEND = 1
}



@Injectable()

export class LogService {

  logLevelId: LogLevel = LogLevel.All;
  logWithDate: boolean = true;
  publishers: LogPublisher[] = [];

  constructor(private publishersService: LogPublishersService) {
    // luam lista de publishers din serviciu
    this.publishers = this.publishersService.publishers;
    //console.log(this.publishers);//teste
  }


  debug(msg: string, location: string, userId: string, userName: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Debug, location, userId, userName, optionalParams);
  }

  info(msg: string, location: string, userId: string, userName: string, ...optionalParams: any[]) {   
    this.writeToLog(msg, LogLevel.Info, location, userId, userName, optionalParams);
  }

  warn(msg: string, location: string, userId: string, userName: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Warn, location, userId, userName, optionalParams);
  }

  error(msg: string, location: string, userId: string, userName: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Error, location, userId, userName, optionalParams);
  }

  fatal(msg: string, location: string, userId: string, userName: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Fatal, location, userId, userName, optionalParams);
  }

  trace(msg: string, location: string, userId: string, userName: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Trace, location, userId, userName, optionalParams);
  }

  log(msg: string, location: string, userId: string, userName: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.All, location, userId, userName, optionalParams);
  }

  private writeToLog(msg: string, logLevelId: LogLevel, location: string, userId: string, userName: string, params: any[]) {

    if (this.shouldLog(logLevelId)) {
            
      let entry: LogEntry = new LogEntry();
      entry.logDate = new Date((new Date().setMilliseconds(new Date().getUTCMilliseconds())+" UTC"));//trebuie sa precizam ca e UTC altfel in local storage si baza de date se salveaza un DateTime care este cu doua ore in urma,in consola totusi e afisata ora/data corecta chiar daca nu atasez UTC
                                                                                                     //EX: In loc de 2022-01-20 14:28:28.8860000 se salveaza  2022-01-20 12:28:28.8860000
      entry.message = msg;
      entry.logLevelId = logLevelId;
      entry.logLevelName = LogLevel[logLevelId];
      entry.location = location;
      entry.userId = userId;
      entry.userName = userName;
      entry.extraInfo = entry.formatParams(params);
      entry.logWithDate = this.logWithDate;

      //console.log(this.publishers);teste
     
      
      for (let logger of this.publishers) {
        //logger.log(entry).subscribe(response => console.log(response));         
        logger.log(entry).subscribe(
          {
            next: (r) => { },
            error: (e) => {

              if (logger.loggerName === "webapi") {
                //daca apare o eroare in API si nu putem salva erorile in baza de date le vom salva in localStorage si le vom afisa si in consola
                let entryLogger: LogEntry = new LogEntry();
                entryLogger.logDate = new Date((new Date().setMilliseconds(new Date().getUTCMilliseconds())+" UTC"));
                entryLogger.message = "An error occurred in LogWebApi";
                entryLogger.logLevelId =logLevelId;
                entryLogger.logLevelName = LogLevel[logLevelId];
                entryLogger.location = "LogWebApi, log method";
                entryLogger.userId = userId;
                entryLogger.userName = userName;
                entryLogger.extraInfo = e;
                entryLogger.logWithDate = this.logWithDate;

                console.error(entryLogger);
                this.publishers.find(item => item.loggerName === "localstorage").log(entryLogger).subscribe();
              }
            },
            complete: () => { }
          }
        );
      }

    }
  }

  private shouldLog(logLevelId: LogLevel): boolean {
    let ret: boolean = false;
    if ((logLevelId >= this.logLevelId && logLevelId !== LogLevel.Off) || this.logLevelId === LogLevel.All) {
      ret = true;
    }
    return ret;
  }



}//LogService Class END


export class LogEntry {
  // Public Properties
  id:number = 0;
  logDate: Date;
  message: string = "";
  location: string = "";
  userId: string = "";
  userName: string = "";
  logLevelId: LogLevel = LogLevel.All;
  logLevelName: string = LogLevel[LogLevel.All];
  logMethodCalledFromID:LogLocationEnum = LogLocationEnum.FRONTEND;
  logMethodCalledFromName:string = LogLocationEnum[LogLocationEnum.FRONTEND];
  extraInfo: any;
  logWithDate: boolean = true;

  public buildLogString(): string {
    let ret: string = "";

    if (this.logWithDate) {
      ret = new Date() + " - ";
    }

    ret += "LogType: " + LogLevel[this.logLevelId];
    ret += " - Message: " + this.message;
    if (this.extraInfo.length) {
      ret += " - Extra Info: " + this.extraInfo;
    }
    ret += " - Location: "+ this.location;

    return ret;
  }

 public  formatParams(params: any[]): string {
    //let ret: string = params.join(",");
    let ret: string ="[";
    // daca sunt mai multe obiecte le delimitam folosind virgula
    if (params.some(p => typeof p == "object")) {
      
      for (let item of params) {
        ret += JSON.stringify(item) + ",";
      }

      var n = ret.lastIndexOf(",");//obtinem indexul ultimei virgule
      ret = ret.substring(0,n);//folosim indexul pentru a prelua tot ce e inainte de virgula si astfel noul string nu mai contine virgula
    }
    
    ret += "]";
    return ret;
  }
}//LogEntry  class END