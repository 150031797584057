import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { LogService } from '../logService/log-service.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {

  userId:string="6663ec3b-6dd6-4074-9d1d-0ca281c51fda";
  userName:string="Admin";
 
  constructor(private injector: Injector, private router: Router, private logger: LogService) { }


  handleError(error: any): void {
    const router = this.injector.get(Router);

    if (error instanceof HttpErrorResponse) {
            var locationError ='';
            locationError = error.error.substring(
            error.error.indexOf("#") + 1,
            error.error.lastIndexOf("#"));
            this.logger.error(error.message, locationError,this.userId, this.userName, error.error.replace("#"+locationError+"#", ''));

      //gestionare erori dupa logare in functie de codul de error status (afisarea unor mesaje pe interfata) 

      // switch (error.status) {
      //   case 401:      //login- Unauthorized 
      //     //this.router.navigateByUrl("/login");          
      //     break;
      //   case 403:     //forbidden
      //     //this.router.navigateByUrl("/unauthorized");
      //     break;
      //   case 404:     //not found
      //     //console.log("Error status:" + error.status +" mesaj eroare");         
      //     break;
      //   case 500:     //internal server error
      //     console.log("Error status:" + error.status + " mesaj eroare");
      //     break;
      //   case 502:     //bad gateway
      //     console.log("Error status:" + error.status + " mesaj eroare");
      //     break;
      //   case 503:     //service unavailable
      //     console.log("Error status:" + error.status + " mesaj eroare");
      //     break;
      //   case 504:   //gateway timeout
      //     console.log("Error status:" + error.status + " mesaj eroare");
      //     break;
      // }
 
     }
     else {      
      // var location = error.stack.replace(error.message, '');
      // location = location.replace("Error:", '');
      this.logger.error(error.message, error.stack,this.userId, this.userName, error);
      //console.log(error);      
     }
  }
}
