
import { Injectable } from '@angular/core';
import { UrlBuilder } from 'src/app/_metronic/shared/url-builder/url-builder';
import { QueryStringParameters } from 'src/app/_metronic/shared/url-builder/query-string-parameters';
import { APICallsConstants } from 'src/app/_metronic/configs/api-calls-constants.config';
@Injectable()
export class ApiEndpointsService {
  constructor(public constants: APICallsConstants) {
     
    }



  /* #region URL CREATOR */
  // URL
  public createUrl( action: string, isMockAPI: boolean = false): string {
    //metoda va creea url-uri de genul acesta ->http://real-domain.com/api/news
    //daca api-ul nu e disponibil sau nu se cunoaste link-ul catre acesta, se poate folosi un dummy-api pentru teste initiale pentru a genera link-urile, trebuie setata valoarea true parametrului isMockAPI-> http://dummy-domain.com/api/news
    const urlBuilder: UrlBuilder = new UrlBuilder(
      isMockAPI ? this.constants.API_MOCK_ENDPOINT :   
      this.constants.API_ENDPOINT,
      action
    );
    return urlBuilder.toString();
  }


  // URL WITH QUERY PARAMS
  public createUrlWithQueryParameters(action: string,  queryStringHandler?: (queryStringParameters: QueryStringParameters) => void ): string
   {//metoda genereaza url-uri de genul -> http://real-domain.com/api/productlist?countryCode=100&postalCode=105600
         const urlBuilder: UrlBuilder = new UrlBuilder(
      this.constants.API_ENDPOINT, 
      action
    );
    // Push extra query string params
    if (queryStringHandler) {
      queryStringHandler(urlBuilder.queryString);
      /*# queryStringHandler este de fapt o functie trimisa ca parametru (vezi in metoda de mai jos,getProductListByCountryAndPostalCodeEndpoint,
       cum se trimite o functie ca parametru)
        # handler-ul preia variabila  queryString de tip QueryStringParameters si aplica asupra ei exact instructiunile scrise in functia transmisa ca parametru
       
        !!!! Metoda getProductListByCountryAndPostalCodeEndpoint este doar un exemplu nu este folosita in aceasta aplicatie

      */
    }
    return urlBuilder.toString();
  }
  
  // URL WITH PATH VARIABLES
  public createUrlWithPathVariables(action: string, pathVariables: any[] = [] ): string 
  {//metoda genereaza url-uri de genul -> http://real-domain.com/api/data/1/200
    //vezi mai jos un exemplu, metoda getDataByIdAndCodeEndpoint
    let encodedPathVariablesUrl: string = '';
    // Push extra path variables
    for (const pathVariable of pathVariables) {
      if (pathVariable !== null) {
        encodedPathVariablesUrl +=
          `/${encodeURIComponent(pathVariable.toString())}`;
      }
    }
    const urlBuilder: UrlBuilder = new UrlBuilder(
      this.constants.API_ENDPOINT,  
      `${action}${encodedPathVariablesUrl}`
    );
    return urlBuilder.toString();
  }
  /* #endregion */



  // public getNewsEndpoint(): string {
  //EXEMPLU DE FOLOSIRE A METODEI, NU ESTE FOLOSITA IN APLICATIE
  //   return this.createUrl('news');
  // }

  // public getNewsEndpoint2(): string {
 //EXEMPLU DE FOLOSIRE A METODEI, NU ESTE FOLOSITA IN APLICATIE
  //   return this.createUrl('news', true);
  // }

  // public getProductListByCountryAndPostalCodeEndpoint(countryCode: string, postalCode: string): string {
    //EXEMPLU DE FOLOSIRE A METODEI, NU ESTE FOLOSITA IN APLICATIE
  //   return this.createUrlWithQueryParameters(
  //     'productlist', 
  //     (qs: QueryStringParameters) => {
  //       qs.push('countryCode', countryCode);
  //       qs.push('postalCode', postalCode);
  //     }
  //   );
  // }


  // public getDataByIdAndCodeEndpoint(id: string,code: number): string {
    //EXEMPLU DE FOLOSIRE A METODEI, NU ESTE FOLOSITA IN APLICATIE
  //   return this.createUrlWithPathVariables('data', [id, code]);
  // }

}