import { Injectable } from '@angular/core';

@Injectable()
export class APICallsConstants {
    // public readonly API_ENDPOINT: string = 'http://upgapp.dynetflow.com';
    public readonly API_ENDPOINT: string = 'http://upgapi.cbc-digital.com';
    // public readonly API_ENDPOINT: string = 'http://localhost:5001';
    public readonly API_MOCK_ENDPOINT: string = 'http://test-purpose-domain.com/api';

     
    public readonly API_DataController_Table5GetMethod ="Data/GetTable5Data"   
    public readonly API_DataController_Table4GetMethod ="Data/GetTable4Data"   
    public readonly API_DataController_Table3GetMethod ="Data/GetTable3Data"   
    public readonly API_DataController_Table2GetMethod ="Data/GetTable2Data"   
    public readonly API_DataController_Table1GetMethod ="Data/GetTable1Data"
       
}