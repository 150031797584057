import { Observable, of, throwError } from 'rxjs';

import { HttpClient , HttpHeaders, HttpParams} from '@angular/common/http';
import { LogEntry, LogLevel } from './log-service.service';
import { catchError, map } from 'rxjs/operators';


export abstract class LogPublisher {
    location: string = '';
    loggerName: string ='';
    abstract log(record: LogEntry): Observable<boolean>
    abstract clear(): Observable<boolean>;
    
}

export class LogConsole extends LogPublisher {

    
    log(entry: LogEntry): Observable<boolean> {
    
        if(entry.logLevelId=== LogLevel.Error || entry.logLevelId === LogLevel.Fatal)
        {
          console.error(entry.buildLogString());
        }
        else if(entry.logLevelId === LogLevel.Warn)
        {
          console.warn(entry.buildLogString());
        }
        else
        {
          console.log(entry.buildLogString());
        }
               
        return of(true);
    }

    clear(): Observable<boolean> {
        console.clear();
        return of(true);
    }     
}

export class LogLocalStorage extends LogPublisher {

    constructor(private logLocation: string, private logName:string) {
  
        super();
        
        // setam denumirea key-ului din local Storage
        this.location = logLocation;
        this.loggerName = logName;
        let item =localStorage.getItem(this.location);                  
        
        //daca nu exista de la o rulare anterioara a aplicatiei il cream
        if(item === null)
        {
            localStorage.setItem(logLocation, "[]");
        }

    }
    
    
    log(entry: LogEntry): Observable<boolean> {
        let ret: boolean = false;
        let values: LogEntry[];
        
        try {
            //preluam datele deja existente in localStorage in key-ul loggingErrorsAndInfo
           
            let item =localStorage.getItem(this.location);                   

            values = JSON.parse( item!);
            
           //adaugam noile date
            values.push(entry);
            
            //punem datele in local storage in key-ul loggingErrorsAndInfo
            localStorage.setItem(this.location, JSON.stringify(values));
            
            
            ret = true;
        } catch (ex) {
            // daca apar erori le afisam in consola
            console.log(ex);
        }
        
        return of(ret);
    }
    
    //curata key-ul din local storage
    clear(): Observable<boolean> {
        localStorage.removeItem(this.location);
        return of(true);
    }    
}

export class LogWebApi extends LogPublisher {
    constructor(private http: HttpClient, private logLocation: string, private logName:string) {
    
        super();
        
        //setam metoda pe care trebuie s-o apelam pentru a salva datele
        this.location = logLocation;
        this.loggerName = logName;
    }
    
    //apelam metoda si introducem datele prin intermediul acesteia intr-o baza de date
    log(entry: LogEntry): Observable<boolean> {

        const httpOptions = {
            headers: new HttpHeaders({            
               'Content-Type': 'application/json' }),
                responseType :'text' as 'json'
        };
   
       return this.http.post(this.location, entry, httpOptions).pipe(
    
        map( response=>{  
            //console.log(response);          
            return true;
        }),        
        catchError(this.handleErrors)        
        );

    
   //test
    // console.log("API--->"+entry.buildLogString());
    // return of(true);

    }
    
    //in caz ca vrem sa stergem  logurile din baza de date, momentan nu o implementez
    clear(): Observable<boolean> {
        // TODO: trebuie sa apelam o metoda de DELETE din API
        return of(true);
    }
    
    private handleErrors(error: any): Observable<any> {
               
        // let errors: string[] = [];
        // let msg: string = "";        
        // msg = "Status: " + error.status;
        // msg += " - Status Text: " + error.statusText;
        // if (error) {
        //     msg += " - Error Message: " + error.message;
        // }
        // errors.push(msg);
                 
        return throwError(error);
    }      
}

export class LogPublisherConfig {
    loggerName: string ='';
    loggerLocation: string='';
    isActive!: boolean;
}